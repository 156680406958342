body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
p {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.card-custom {
  color: #fff !important;
}
.card-custom-user {
  background: linear-gradient(45deg, #31b3e0, #1d97c2) !important;
  border-color: #1d97c2 !important;
}
.card-custom-story {
  background: linear-gradient(45deg, #ffc721, #edb100) !important;
  border-color: #edb100 !important;
}
.card-custom-duration {
  background: linear-gradient(45deg, #78cae2, #4ebada) !important;
  border-color: #4ebada !important;
}
.card-custom-title {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}
.card-custom-strong {
  font-size: 20px;
  font-weight: 500;
}
.table-custom{
  border: solid 1px #ccc;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
}
.ant-tabs-tab{
  /* border: solid 1px #ccc !important;
 */
 /* padding:0px !important; */
 /* color:#fff !important */
}
.ant-tabs-tab-active{
  /* color:#fff !important */
}

.tab-custom-user{
  /* background: linear-gradient(45deg, #78cae2, #4ebada) !important;
  border-color: #4ebada !important; */
  /* color:#000 !important */
}
.tab-custom-story{
  /* background: linear-gradient(45deg, #ffc721, #edb100) !important;
  border-color: #edb100 !important; */
  /* color:#000 !important */
}
.tab-custom-category{
  /* background: linear-gradient(45deg, #78cae2, #4ebada) !important;
  border-color: #4ebada !important; */
  /* color:#000 !important */
}
/* .header-navbar {
  background: #fff !important;
  padding: 6px 24px !important;
  position: relative;
  display: block;
  height: auto !important;
  line-height: inherit !important;
} */
.ant-menu-item-selected > a {
  font-weight: 500 !important;
}