$mobile-width: 480px;
$tablet-width: 768px;
$tablet-pro-width: 992px;
$desktop-width: 1080px;
@mixin mobile {
  @media (max-width: #{$mobile-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width - 1px}) and (max-width: #{$tablet-pro-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$tablet-pro-width}) and (max-width: #{$desktop-width}) {
    @content;
  }
}
html {
  background-color: #232a3b !important;
  font-family:  Tahoma, Geneva, Verdana, sans-serif !important;
  line-height: 1.5;
}
html body {
  color: #232a3b !important;
  font-family:  Tahoma, Geneva, Verdana, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  &::-webkit-scrollbar {
    width: 7px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0.44, rgb(1, 26, 75)),
      color-stop(0.72, rgb(1, 23, 51)),
      color-stop(0.86, rgb(0, 12, 23))
    );
  }
}
label {
  margin-bottom: 0;
}
a {
  color: #a0aec0;
  &:hover {
    color: rgba(0, 0, 0, 0.8) !important;
  }
}
@include mobile {
  html body {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .ant-calendar-range {
    width: 100% !important;
  }
  .main-layout {
    border-radius: 30px;
    overflow: hidden;
    // padding: 22px 0 0 !important;
    max-width: 100%;
    @include mobile {
      overflow: inherit !important;
    }
    @include tablet {
      overflow: inherit !important;
    }
  }
  .ant-modal-body {
    padding: 12px 6px !important;
  }
  .card-body {
    padding: 6px !important;
  }
  .ant-form-item-label {
    padding: 0 !important;
  }
}
.nav-tabs .nav-item {
  margin: 6px 0 !important;
}
.confirm-action {
  & .ant-result {
    padding: 0 !important;
  }
}
.ant-cascader-menu-item {
  white-space: initial !important;
  border-bottom: 1px solid #e8e8e8;
}
.ant-calendar-range .ant-calendar-input,
.ant-calendar-range .ant-calendar-time-picker-input {
  text-align: center;
}
.ant-calendar-range-middle {
  left: 65% !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #e74c3c !important;
  border-color: #ffa500 !important;
}
// .ant-spin-dot-item,
// .ant-switch-checked,
// .ant-steps-item-finish
//   > .ant-steps-item-container
//   > .ant-steps-item-tail::after,
// .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
// .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
//   background-color: #e74c3c !important;
// }
.ant-calendar-range .ant-calendar-in-range-cell::before,
.ant-cascader-menu-item:hover,
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
.ant-table-thead
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-thead
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.transfer-item.active:hover,
.transfer-item.active:focus,
.ant-calendar-selected-day .ant-calendar-date,
.ant-calendar-date:hover {
  background-color: #f7aba723 !important;
}
// .ant-input,
// .ant-select-selection,
// .ant-select {
//   // height: 32px !important;
//   // min-width: 250px;
// }
form:not(.ant-form-vertical) :not(.ant-input-group-wrapper) > .ant-input-group,
form:not(.ant-form-vertical) .ant-input-group-wrapper {
  top: 0 !important;
}
.ant-pagination-item-active,
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled),
.ant-upload.ant-upload-select-picture-card:hover,
.ant-cascader-picker:focus .ant-cascader-input,
.ant-cascader-picker-label:hover + .ant-cascader-input,
.ant-select-selection:hover,
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
.ant-select-open .ant-select-selection,
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active,
.ant-input:focus,
.ant-input:hover,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner,
.ant-time-picker-input:hover {
  border-color: #ffa500 !important;
  box-shadow: 0 0 0 2px rgba(255, 24, 24, 0.062) !important;
}
.ant-calendar-today .ant-calendar-date {
  border-color: #ffa500 !important;
  color: #bd0000 !important;
}
.ant-calendar-footer .ant-tag,
.menu-selector-item-selected,
.menu-selector-item:hover {
  color: #ffa500 !important;
  border-color: #ffa39e !important;
  background-color: #fff1f0 !important;
}
// .ant-tabs-tab-btn-disabled {
//   display: none;
// }
.ant-tabs-tab-arrow-show {
  height: 32px !important;
  width: 22px !important;
}
.ant-pagination-item-link,
.side-nav .ant-layout-sider-trigger,
// .ant-btn .anticon {
//   display: flex !important;
//   justify-content: center !important;
//   align-items: center !important;
// }
.ant-avatar {
  align-items: center !important;
  display: inline-flex !important;
  justify-content: center !important;
}
.ant-tabs-nav-wrap {
  padding: 0;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 6px 8px !important;
}
.ant-modal-header {
  padding: 8px 12px !important;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
  height: auto !important;
}
.ant-tabs-bar {
  margin: 0 !important;
}
.ant-modal-close-x {
  width: 2rem !important;
  height: 2rem !important;
  line-height: 2rem !important;
}
.custom-tabs {
  overflow: inherit !important;
  position: relative;
  & .ant-tabs-nav-container-scrolling {
    padding-right: 0px;
    padding-left: 0px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    background-color: white;
    & .ant-tabs-nav-scroll {
      overflow: auto;
      overflow-x: scroll;
      padding: 6px;
      // &::-webkit-scrollbar {
      //   width: 0;
      //   height: 5px;
      //   background-color: #f5f5f5;
      // }
      // &::-webkit-scrollbar-track {
      //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      //   background-color: #f5f5f5;
      // }
      // &::-webkit-scrollbar-thumb {
      //   border-radius: 8px;
      //   background-image: -webkit-gradient(
      //     linear,
      //     left bottom,
      //     left top,
      //     color-stop(0.44, rgb(1, 26, 75)),
      //     color-stop(0.72, rgb(1, 23, 51)),
      //     color-stop(0.86, rgb(0, 12, 23))
      //   );
      // }
    }
  }
  .ant-tabs-tab-btn-disabled {
    display: none;
  }
  & .ant-tabs-tab-prev {
    box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
    display: none;
  }
  & .ant-tabs-tab-next {
    box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15);
    display: none;
  }

  & .ant-tabs-ink-bar {
    background-color: transparent !important;
  }
  & .ant-tabs-nav-container {
    overflow: inherit !important;
    & .ant-tabs-tab-next {
      right: -20px !important;
      @include mobile {
        right: -5px !important;
      }
    }
    & .ant-tabs-tab-prev {
      left: -20px !important;
      @include mobile {
        left: -5px !important;
      }
    }
    & .ant-tabs-tab-next-icon,
    & .ant-tabs-tab-prev-icon {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      & i {
        font-size: 20px;
      }
    }
  }
  & .ant-tabs-tab {
    text-align: center !important;
    // padding-top: 0.5rem !important;
    // padding-bottom: 0.5rem !important;
    // padding-left: 0.75rem !important;
    // padding-right: 0.75rem !important;
    padding: 0.75rem !important;
    font-weight: 500 !important;
    cursor: pointer !important;
    box-shadow: 0px 3px 20px #0000000b !important;
    // background-color: rgba(255, 255, 255, 1) !important;
    background-color: transparent !important;
    // border-radius: 0.375rem !important;
    border-radius: 22px !important;
    position: relative !important;
    min-width: 100px !important;
    // display: flex !important;
    // justify-content: center !important;
    align-items: center !important;
    line-height: 14px !important;
    // max-height: 32px !important;
    max-height: 36px !important;
    height: 36px !important;
    color: #e74c3c !important;
    margin-right: 19px !important;
    border-color: transparent !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    &:hover {
      border-color: #e9ecef #e9ecef #dee2e6 !important;
      color: rgba(0, 0, 0, 0.8) !important;
    }
    &:last-child {
      margin-right: 0 !important;
    }
    &.ant-tabs-tab-active {
      background-color: #e74c3c !important;
      color: #fff !important;
    }
  }
  & .ant-tabs-bar {
    border-bottom: 0 !important;
  }
}
.ant-time-picker-panel-select li:focus {
  color: #e74c3c !important;
  border-color: #ffa500 !important;
}
.row-active,
.row-hover:hover {
  background-color: #f7aba723 !important;
}
// button {
//   -webkit-appearance: button;
//   -webkit-writing-mode: horizontal-tb !important;
//   text-rendering: auto;
//   color: -internal-light-dark-color(buttontext, rgb(170, 170, 170));
//   letter-spacing: normal;
//   word-spacing: normal;
//   text-transform: none;
//   text-indent: 0px;
//   text-shadow: none;
//   display: inline-block;
//   text-align: center;
//   align-items: flex-start;
//   cursor: default;
//   background-color: -internal-light-dark-color(rgb(239, 239, 239), rgb(74, 74, 74));
//   box-sizing: border-box;
//   margin: 0em;
//   font: 400 13.3333px Arial;
//   padding: 1px 6px;
//   border-width: 2px;
//   border-style: outset;
//   border-color: -internal-light-dark-color(rgb(118, 118, 118), rgb(195, 195, 195));
//   border-image: initial;
// }
.main-btn {
  background-color: #e5e5e5;
  border: 1px solid #e5e5e5;
  color: #a6a6a6;
  display: inline-block;
  line-height: 35px;
  margin: 8px;
  padding: 0 15px;
  font-size: 15px;
  position: relative;
  opacity: 0.999;
  border-radius: 3px;
  transition: all 0.3s;
  box-sizing: inherit;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  &::before,
  &::after {
    border-color: #e74c3c !important;
    content: "";
    border-style: solid;
    position: absolute;
    z-index: 5;
    border-radius: 3px;
    box-sizing: content-box;
    transition: all 0.3s;
  }
  &::before {
    width: 0;
    height: 100%;
    border-width: 1px 0 1px 0;
    top: -1px;
    right: 0;
  }
  &::after {
    width: 100%;
    height: 0;
    border-width: 0 1px 0 1px;
    bottom: 0;
    left: -1px;
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
  }
  &:hover {
    color: #e74c3c !important;
    background-color: transparent;
    &::after {
      height: 100%;
    }
    &::before {
      width: 100%;
    }
  }
}
// .ant-btn {
//   display: inline-flex !important;
//   align-items: center;
//   justify-content: center;
//   margin: 0.5rem;
//   // padding: 0.5rem 0.75rem !important;
//   padding: 0.75rem !important;
//   border: solid 1px #ccc !important;
//   border-radius: 6px !important;
//   outline: none !important;
//   font-family: inherit !important;
//   font-size: 1em !important;
//   font-weight: 500 !important;
//   line-height: 1.2rem !important;
//   text-decoration: none !important;
//   text-align: center !important;
//   transition: all 0.3s ease-out !important;
//   height: 36px !important;
//   &:hover {
//     background-color: #e74c3c !important;
//     color: white !important;
//     box-shadow: 0 0 0 0.1875rem white, 0 0 0 0.375rem #e74c3c;
//     & i {
//       color: white !important;
//     }
//   }
//   &[disabled],
//   &.disabled {
//     background-color: #e5e5e5 !important;
//     border: 1px solid #e5e5e5 !important;
//     color: #a6a6a6 !important;
//   }
// }
// .ant-btn-link {
//   color: #000 !important;
//   background-color: transparent;
// }
// .ant-btn-primary {
//   background-color: #e74c3c !important;
//   color: white !important;
// }
.ant-divider-horizontal {
  margin: 12px 0;
}
.ant-form-item-label {
  line-height: 24px !important;
}
.ant-table-body {
  // &::-webkit-scrollbar {
  //   width: 7px;
  //   height: 7px;
  //   background-color: #f5f5f5;
  // }
  // &::-webkit-scrollbar-track {
  //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  //   background-color: #f5f5f5;
  // }
  // &::-webkit-scrollbar-thumb {
  //   border-radius: 8px;
  //   background-image: -webkit-gradient(
  //     linear,
  //     left bottom,
  //     left top,
  //     color-stop(0.44, rgb(1, 26, 75)),
  //     color-stop(0.72, rgb(1, 23, 51)),
  //     color-stop(0.86, rgb(0, 12, 23))
  //   );
  // }
}
.app {
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

html body {
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  padding-left: 2rem;
  // padding-right: 2rem;
  font-family: Roboto;
  font-size: 0.875rem;
  background-color: transparent;
  border-color: #313a55;
}
@include mobile {
  .none-mobile {
    display: none;
  }

  .none-desktop {
    display: block !important;
  }
}

@include tablet {
  .none-mobile {
    display: none !important;
  }

  .none-desktop {
    display: block !important;
  }
}

@include desktop {
  .none-mobile {
    display: none !important;
  }

  .none-desktop {
    display: block !important;
  }
}

.none-desktop {
  display: none;
}
// side nav
.side-nav {
  // padding-right: 1.25rem !important;
  // padding-right: 0;
  padding-bottom: 4rem !important;
  // overflow-x: hidden;
  background-color: #232a3b !important;
  &.ant-layout-sider-collapsed {
    width: 0 !important;
    max-width: 0 !important;
    min-width: 0 !important;
    flex: 0 0 0 !important;
    & .ant-layout-sider-trigger {
      width: 2rem !important;
    }
    & .ant-menu-inline-collapsed,
    & .logo,
    & .navbar,
    & .ant-divider {
      display: none;
    }
  }
  & .ant-layout-sider-trigger {
    border-radius: 8px;
    background: rgb(0, 12, 23);
    left: 0;
    width: calc(200px + 2rem) !important;
    @include mobile {
      left: 0px;
      top: 5px;
      width: 2rem !important;
      z-index: 10;
    }
    @include tablet {
      left: 0px;
      top: 5px;
      width: 2rem !important;
      z-index: 10;
    }
  }
  & .side-menu {
    background: transparent !important;
    // margin-top: 25%;
    margin-top: 120px;
    // padding-left: 20px;
    & > li {
      // background-color: transparent !important;
      height: auto;
      line-height: auto;
      padding-left: 0 !important;
      padding-right: 0 !important;
      & a {
        color: #a0aea0;
      }
      & > .active {
        background-color: #f0f2f5 !important;
        & .custom-menu-item-title {
          color: rgba(0, 0, 0, 1);
          font-weight: 700;
        }
        & svg {
          color: rgba(0, 0, 0, 1);
          font-weight: 700;
        }
      }
    }
  }
  & .ant-menu-submenu-title {
    padding-left: 0.5rem !important;
  }
  & .ant-menu-sub {
    background-color: #1e2533 !important;
    box-shadow: none !important;
    // border-top-left-radius: 20px !important;
    // border-bottom-left-radius: 20px !important;
    & li {
      background: transparent !important;
      height: auto !important;
    }
    & li.ant-menu-submenu {
      padding-left: 1.25rem !important;
    }
  }
  & .ant-menu-item,
  & .ant-menu-item-selected {
    padding: 0 !important;
    max-height: 40px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    a {
      height: 40px;
      display: flex;
      align-items: center;
      padding-left: 1rem;
      color: #fff;
      color: rgba(255, 255, 255, 1);
      margin-bottom: 0.25rem;
      position: relative;
      border-radius: 9999px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      -webkit-animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
      animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s;
      // border-top-left-radius: 30px;
    }
  }
  & .ant-menu-item-active {
    a {
      color: #f0f2f5 !important;
    }
  }
  & .custom-menu-item-title {
    width: 100%;
    margin-left: 0.75rem;
    // display: flex;
    align-items: center;
    color: rgba(160, 174, 192, 1);
  }
  & .ant-layout-sider-children {
    position: fixed;
    overflow-y: scroll;
    padding-bottom: 100px;
    &::-webkit-scrollbar {
      width: 0;
      background-color: transparent;
    }
    width: 200px;
  }
}
// main content
.headerNavbar {
  background: #fff !important;
  padding: 6px 24px !important;
  position: relative;
  display: block;
  height: auto !important;
  line-height: inherit !important;
}
.header-navbar {
  display: flex;
  justify-content: flex-end;
  // padding: 24px;
  & .action-header {
    color: #2b3035;
    background-color: #edf0f2;
    border-color: #edf0f2;
    margin: 0 6px;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
}
.main-content {
  padding: 12px 12px;
  @include mobile {
    padding: 6px 4px;
  }
  & .navbar {
    box-shadow: none !important;
    border-bottom: 1px solid #313a55;
    @include mobile {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.modal-withdraw {
  & .ant-modal {
    max-width: 800px;
    min-width: 800px;
    @include mobile {
      max-width: 100%;
      min-width: auto;
      width: auto;
      padding: 0 10px;
    }
    @include tablet {
      max-width: 100%;
      min-width: 100%;
      width: auto;
      padding: 0 10px;
    }
  }
}
.detail-modal-wallet {
  width: 100%;
  max-width: 420px;

  & .ant-modal-body {
    padding: 12px 0 36px !important;
    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 10px;
      bottom: -5px;
      left: 0;
      right: 0;
      background: linear-gradient(
          45deg,
          transparent 40%,
          #fff 40%,
          #fff 60%,
          transparent 60%
        ),
        linear-gradient(
          -45deg,
          transparent 40%,
          #fff 40%,
          #fff 60%,
          transparent 60%
        );
      background-size: 15px 20px;
      background-position: -2px -10px;
      margin: auto;
    }
  }
  & .ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  & .modal-result {
    padding: 0 6px !important;
    display: inline-block;
    & .ant-result-icon {
      margin-bottom: 0;
      & > .anticon {
        font-size: 22px !important;
      }
    }
  }
  & .modal-result-label {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 500;
  }
  & .modal-result-text {
    font-size: 14px;
    color: #e74c3c;
    font-weight: 700;
    margin-bottom: 0;
    & span {
      color: #333333;
      font-style: italic;
    }
  }
}
.payment-modal.modal-withdraw {
  min-width: 80%;
  max-width: 80%;
  width: 100%;
  @include mobile {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
  @include tablet {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
}
// with table
.stt {
  min-width: 50px;
}
.name {
  min-width: 350px;
  text-align: left;
  max-width: 350px;
}
.price,
.refund,
.avt,
.quantity,
.status {
  min-width: 100px;
}
.action {
  min-width: 200px;
  &.w-250 {
    min-width: 250px;
  }
}

// product
.notification-container {
  & h5 {
    font-size: 14px;
  }
  & .notification-icon {
    width: 28px !important;
    height: 28px !important;
  }
  & p {
    font-size: 12px;
  }
  & i {
    font-size: 11px;
  }
}
.cardProduct {
  // margin: 15px 0;
  height: auto;
  border-radius: 8px;
  border: none !important;
  box-shadow: 0px 3px 20px #0000000b;
  // padding: 0.75rem;
  &.addOrder .ant-card-meta-title {
    font-size: 12px;
    margin-bottom: 4px;
  }
  &:hover .ant-card-cover::before {
    opacity: 1;
  }
  &:hover .count-social {
    top: 50%;
    opacity: 1;
  }
  & .count-social {
    position: absolute;
    left: 50%;
    top: 100%;
    right: 0;
    transform: translate(-50%, -50%);
    display: block;
    text-align: center;
    opacity: 0;
    z-index: 10;
    & ul {
      padding: 0;
      margin: 0;
      list-style: none;
      & li {
        color: white;
      }
    }
  }
  & del {
    font-size: 90%;
    color: #a3a3a3;
    margin-right: 5px;
  }
  & .tagProduct {
    width: auto;
    position: absolute;
    bottom: 0;
    right: 0;
    &.qualityStock {
      left: 0;
      right: inherit;
    }
  }
  & .saleOff {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    & .badge {
      float: left;
      // margin-left: 0.3125rem;
      background-color: rgba(147, 14, 15, 0.85);
      width: 38px;
      height: 35px;
      display: inline-block;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      position: relative;
      padding: 4px 2px 3px;
      font-weight: 700;
      border-radius: 0;
      &:after {
        content: "";
        width: 0;
        height: 0;
        left: 0;
        bottom: -4px;
        position: absolute;
        border-color: transparent rgba(147, 14, 15, 0.85);
        border-style: solid;
        border-width: 0 19px 4px;
      }
      & .percent {
        // display: flex;
        // flex-direction: column;
        text-align: center;
        position: relative;
        font-weight: 700;
        line-height: 0.8125rem;
        color: white !important;
        text-transform: uppercase;
        font-size: 0.75rem;
      }
      & .wrapper {
        color: #000 !important;
        margin: 2px 0;
      }
    }
  }
  & .bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-self: stretch;
    flex: 1 1 0%;
    & .totalSold {
      position: relative;
      & .ant-progress .ant-progress-outer .ant-progress-inner {
        background-color: rgb(253, 218, 200) !important;
      }
      & .ant-progress-line {
        z-index: 10;
      }
      & .content {
        position: absolute;
        top: 0px;
        left: 0px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        width: 100%;
        height: 100%;
        // z-index: 2;
        padding: 0px 4px;
        & .text {
          position: absolute;
          top: 0px;
          left: 0px;
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          width: 100%;
          height: 100%;
          color: black;
          font-size: 12px;
          text-align: center;
          margin: 0px;
          font-weight: 700;
          z-index: 12;
          & .icon {
            margin-right: 20px;
          }
        }
      }
    }
    & .countDown span {
      font-size: 16px;
    }
  }
  & .ant-card-actions {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
  & .ant-card-cover {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.8);
      transition: all 0.5s ease-in-out;
      opacity: 0;
      z-index: 1;
    }
    & img {
      border-radius: 0.375rem;
      min-height: 10.3125rem;
      max-height: 10.3125rem;
      object-fit: cover;
      width: 100%;
      height: 100%;
      &.img-product {
        min-height: 6rem;
        max-height: 6rem;
        object-fit: contain;
      }
    }
  }
  & .ant-card-head {
    top: 0;
    right: -10px;
    position: absolute;
    text-transform: uppercase;
    z-index: 1;
    border-radius: 4px;
    padding: 0 1rem;
    border-bottom: none;
    min-height: auto;
    & .card-product__status {
      padding: 5px;
      border-radius: 4px;
      &.pending {
        background-color: #f3ba2f;
      }
      &.approved {
        background-color: #53dc98;
      }
      &.disabled {
        background-color: #bd0000;
      }
      &.rejected {
        background-color: #bd0000;
      }
    }
    span {
      color: black;
      font-size: 10px;
      font-weight: 700;
    }
    & .ant-card-extra {
      padding: 5px 0;
    }
  }
  & .ant-card-body {
    border-top: 1px solid #e6e6e6;
    // margin-top: 10px;
    padding: 12px 4px;
  }
  & .ant-card-meta-title {
    text-align: center;
    text-transform: uppercase;
  }
  & .ant-card-meta-description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    & ul {
      padding: 0;
      text-align: center;
      & li {
        list-style: none;
        display: inline;
        margin: 5px 10px;
        & i {
          font-size: 14px;
          vertical-align: 0;
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
    & .lineCardProductRight {
      padding: 0 8px;
      // border-left: 1px solid #e6e6e6;
      border-right: 1px solid #e6e6e6;
    }
    & .lineCardProductLeft {
      padding: 0 8px;
      border-left: 1px solid #e6e6e6;
      // border-right: 1px solid #e6e6e6;
    }
    & .ant-form-item {
      margin-bottom: 0;
    }
    & .ant-form-item-label {
      line-height: initial;
    }
    & .ant-form-item-control {
      text-align: right;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      transition-delay: 2s;
      line-height: initial;
      & span {
        color: #a3a3a3;
        font-size: 18px;
      }
      &:hover {
        overflow: visible;
        transition-delay: 2s;
      }
    }
  }
}
// load
.loadData {
  text-align: center;
  background: rgba(225, 225, 225, 0.2);
  align-self: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}
// modal css
.ant-modal-body {
  padding: 12px 24px;
  & .ant-form-item {
    margin-bottom: 0;
  }
}
.anticon {
  vertical-align: 0;
}
// pagination
.paginationProduct {
  position: relative;
  margin: 25px 0;
  text-align: right;
}
// search product
.searchProduct {
  max-width: 300px;
  margin: 25px 0 5px;
  // & .ant-btn {
  //   background-color: #bd0000;
  //   border-color: #bd0000;
  //   &:hover {
  //     background-color: #bd0000;
  //     border-color: #bd0000;
  //   }
  // }
  & .ant-input:hover,
  & .ant-input:focus {
    border-color: #bd0000;
    box-shadow: 0 0 0 2px rgba(255, 24, 24, 0.2) !important;
  }
}

.product-form .ant-form-item-label label {
  margin-bottom: 0px !important;
}
.product-form .ant-form-item {
  margin-bottom: 5px;
}

.product-form
  .ant-form-item
  .ant-form-item-control-wrapper
  .ant-form-item-control {
  line-height: unset !important;
}
.ant-form-item-control {
  line-height: inherit !important;
}
// detail product
.detail {
  margin-bottom: 40px;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  & .image-gallery {
    position: relative;
    padding: 24px 24px 24px 20px;
    border-right: 2px solid rgb(247, 247, 247);
    &:hover .image-gallery-icon {
      opacity: 1;
    }
    & .image-gallery-image {
      max-height: 450px;
      min-height: 450px;
      object-fit: cover;
    }
    & .image-gallery-thumbnail-image {
      max-height: 82px !important;
      min-height: 82px !important;
      max-width: 82px !important;
      min-width: 82px !important;
      object-fit: cover;
    }
    &-icon:hover {
      color: #e74c3c !important;
      transition: all 0.3s ease-in-out;
    }
    &-icon {
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }
    &-thumbnail.active,
    &-thumbnail:hover,
    &-thumbnail:focus {
      border-color: #e74c3c !important;
    }
  }
  & .body {
    /* border-bottom: 1px solid rgb(236, 236, 236); */
    padding: 16px;
    & .seller-badge {
      font-size: 0.75rem;
      vertical-align: middle;
      margin-right: 10px;
      border-radius: 2px;
      // display: block;
      align-items: center;
      position: relative;
      background-color: #ee4d2d;
      padding: 0.25rem 0.25rem;
      text-transform: capitalize;
      color: #fff;
      font-weight: 500;
      line-height: 1;
      white-space: nowrap;
      cursor: default;
      user-select: none;
    }
    & .page-title {
      text-transform: none;
      font-weight: 700;
      font-size: 20px;
      color: #444;
      margin: 5px 0;
      display: inline-flex;
      align-items: center;
    }
    & .review-actions {
      display: table-cell;
      line-height: 28px;
      vertical-align: middle;
      position: relative;
      top: 0;
      & .action-inline {
        display: inline-block;
        margin-right: 5px;
        padding: 0 15px;
        border-right: 1px solid rgba(0, 0, 0, 0.14);
        &:last-child {
          border-right-color: transparent;
        }
        & .count {
          color: #ee4d2d;
          border-bottom: 1px solid #ee4d2d;
          font-size: 1rem;
          margin-right: 5px;
          padding-bottom: 1px;
        }
      }
    }
    & .price-detail-product {
      margin-top: 16px;
      padding: 12px 20px;
      background: #fafafa;
      flex-direction: column;
      display: flex;
      // align-items: center;
      & .price-item {
        display: flex;
        align-items: center;
        & del.first-price {
          font-size: 0.8rem;
          text-decoration: line-through;
          color: #929292;
          margin-right: 10px;
        }
        & .price-decreased {
          font-size: 1.2rem;
          font-weight: 500;
          color: #ee4d2d;
        }
        & .promotion-value {
          font-size: 0.75rem;
          color: #fff;
          text-transform: uppercase;
          background: #ee4d2d;
          border-radius: 2px;
          padding: 2px 4px;
          font-weight: 600;
          line-height: 1;
          margin-left: 15px;
          white-space: nowrap;
        }
      }
    }
  }
  & p {
    margin-bottom: 0;
  }
  & .status-product {
    margin-top: 1.5625rem;
    padding: 0 1.25rem;
    & label.label-product {
      color: #757575;
      font-size: 14px;
    }
    & .content-product {
      color: #222;
      font-weight: 600;
      & svg {
        font-size: 1.3rem;
        margin-right: 5px;
      }
    }
  }
  & .base {
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-weight: 500;
    margin: 0;
    vertical-align: sub;
    max-height: 3rem;
    line-height: 1.5rem;
    overflow: hidden;
    max-width: 41.5625rem;
    font-size: 1.25rem;
    word-wrap: break-word;
  }
  & .content-title {
    background: rgba(0, 0, 0, 0.02);
    color: rgba(0, 0, 0, 0.87);
    font-size: 1.125rem;
    padding: 0.875rem;
    text-transform: capitalize;
  }
  & .main-content {
    margin: 1.875rem 0.9375rem 0.9375rem;
    & .content {
      white-space: pre-wrap;
      color: rgba(0, 0, 0, 0.8);
      font-size: 0.875rem;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.875rem;
    }
  }
}
.box {
  & .ant-card-head {
    // min-height: 36px !important;
    // max-height: 36px !important;
    &-wrapper {
      max-height: 36px !important;
    }
    &-title {
      padding: 6px 0;
    }
  }
}
.transfer-btn {
  display: flex !important;
  max-height: calc(100vh - 420px);
  height: calc(100vh - 420px);
  align-items: center;
  justify-content: center;
  @include desktop {
    height: calc(100vh - 920px);
    max-height: calc(100vh - 920px);
  }
  @include tablet {
    height: auto;
    transform: rotateZ(90deg);
  }
  @include mobile {
    height: auto;
    transform: rotateZ(90deg);
    max-height: 100%;
  }
}
.selector-product-container {
  overflow: auto;
  max-height: calc(100vh - 370px);
  @include desktop {
    max-height: calc(100vh - 920px);
  }
  &::-webkit-scrollbar {
    width: 7px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0.44, rgb(1, 26, 75)),
      color-stop(0.72, rgb(1, 23, 51)),
      color-stop(0.86, rgb(0, 12, 23))
    );
  }
  max-height: calc(100vh - 420px);
  @include desktop {
    max-height: calc(100vh - 920px);
  }
}
.company-stock-product-container {
  & .company-stock-product-item {
    display: flex;
    align-items: center;
    &:hover {
      background-color: #f7aba723;
    }
  }
  &::-webkit-scrollbar {
    width: 7px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0.44, rgb(1, 26, 75)),
      color-stop(0.72, rgb(1, 23, 51)),
      color-stop(0.86, rgb(0, 12, 23))
    );
  }
}
// Profile
.profile {
  display: flex !important;
  justify-content: center;
  & .avt-profile {
    // display: flex;
    // align-items: center;
  }
  & .name-staff {
    font-size: 1.125rem;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
  }
  & .phone-staff {
    color: rgba(113, 128, 150, 1);
    margin-bottom: 0;
  }
}
.menu-selector-container {
  // &::-webkit-scrollbar {
  //   width: 7px;
  //   background-color: #f5f5f5;
  // }
  // &::-webkit-scrollbar-track {
  //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  //   background-color: #f5f5f5;
  // }
  // &::-webkit-scrollbar-thumb {
  //   border-radius: 8px;
  //   background-image: -webkit-gradient(
  //     linear,
  //     left bottom,
  //     left top,
  //     color-stop(0.44, rgb(1, 26, 75)),
  //     color-stop(0.72, rgb(1, 23, 51)),
  //     color-stop(0.86, rgb(0, 12, 23))
  //   );
  // }
}
// withdraw, deposit
.money-deposit {
  transition: all 0.5s ease-in-out;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  transform: translateX(var(--transform-translate-x))
    translateY(var(--transform-translate-y)) rotate(var(--transform-rotate))
    skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y))
    scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
  cursor: pointer;
  opacity: 0.7;
  &.money-active,
  &:hover {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
    opacity: 1;
  }
  &.money-active {
    background-image: radial-gradient(
      circle closest-side,
      #e74c3c,
      transparent
    );
  }
}
// otp input
.otpInput {
  width: 32px !important;
  height: 32px;
  font-size: 32px;
  line-height: 32px;
  // padding: 6px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  -webkit-text-security: disc;
  &.error {
    border-color: red !important;
  }
}
// transfer
.transfer-depart {
  max-height: 300px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 7px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0.44, rgb(1, 26, 75)),
      color-stop(0.72, rgb(1, 23, 51)),
      color-stop(0.86, rgb(0, 12, 23))
    );
  }
}
// selectCustom
.selectCustom .ant-select-arrow {
  // display: flex;
  // left: 0;
  // position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border-left: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: inline-flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  top: 6px;
  right: 0;
}
// stock filter
.stock-filter {
  position: relative;
  & .box {
    padding: 0.5rem 8px;
    border-color: transparent;
    box-shadow: none;
  }
  & .ant-tabs-nav-scroll {
    white-space: normal;
  }
  & .ant-tabs-nav .ant-tabs-tab {
    margin: 6px !important;
    // background-color: rgb(247, 247, 247);
    // border: 1px solid #e74c3c !important;
  }
  & .ant-anchor-wrapper {
    margin-left: 0;
    padding-left: 0;
    height: calc(100vh - 100px);
    & .ant-anchor-ink {
      display: none;
    }
  }
  & .ant-anchor {
    padding-left: 0;
  }
}

form:not(.ant-form-vertical) .ant-input-group-wrapper {
  top: 0 !important;
}
// transfer custom
.transfer-product-table {
  border-collapse: collapse;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: auto;
  max-height: 240px;
  height: 240px;
  box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
  & * {
    position: relative;
  }
  & table {
    width: 100%;
    border-collapse: collapse;
  }
  & .ant-table-placeholder {
    border-bottom: 0;
  }
  & thead {
    box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);

    & tr {
      & th {
        font-size: 14px;
        color: #fa4251;
        line-height: 1.4;
        background-color: white;
        font-weight: 700;
        padding-top: 18px;
        padding-bottom: 18px;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 9;
        box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.1);
      }
    }
  }

  & tbody {
    & tr {
      height: 50px;
      color: #808080;
      line-height: 1.2;
      font-weight: unset;
      &:nth-child(even) {
        background-color: #f5f5f5;
      }
    }
  }
  & td,
  & th {
    // border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  & .nameProduct {
    min-width: 220px;
    max-width: 250px;
    width: auto;
  }
}
// side bar css new
.newSidebar {
  position: relative;
  background-color: #e74c3c;
  // z-index: 1;
  &.ant-layout-sider-collapsed .logo {
    width: 80px;
  }
  & .logo {
    overflow: auto;
    position: relative;
    width: 200px;
    padding: 5px 10px;
    border-bottom: 1px solid #e6e6e6;
    // background-color: #bd0000;
    // box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    // width: calc(220px + 1rem);
    width: 200px;
    // left: 0;
    /* width: 100%; */
    margin-left: -20px;
    /* position: fixed; */
    text-align: center;
  }
  & .ant-menu {
    background: #e74c3c;
    // height: calc(100% - 150px);
    line-height: 28px;
    overflow: auto;
    position: relative;
    width: 200px;
    // top: 10%;
    // min-height: 100vh;
    overflow-y: scroll;
    &::-webkit-scrollbar-thumb {
      border-radius: 1px;
      // background-image: url(../images/background/bg4.png);
    }
    &::-webkit-scrollbar {
      width: 0px;
    }
    &-inline-collapsed {
      width: 80px !important;
      top: 7%;
      & > .ant-menu-item {
        padding: 0 32px !important;
        text-overflow: clip;
        & a {
          padding: 0.5rem 0;
          & span {
            opacity: 0 !important;
            display: inline-block;
            max-width: 0;
          }
        }
      }
    }
    &-item {
      padding-left: 0 !important;
      height: auto;
      line-height: 28px;
      margin: 0 !important;
      &.active {
        background-color: #bd0000 !important;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
        & > a {
          color: #ffe600;
          font-weight: 700;
        }
      }
      &-selected {
        background-color: transparent !important;
      }
      & > a {
        color: white;
      }
      & i {
        font-size: 14px;
        margin-right: 5px;
      }
    }
  }
  & .ant-layout-sider-trigger {
    z-index: 10;
    background-color: #bd0000;
    color: #ffe600;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
  }
}
.ant-layout-sider-zero-width-trigger {
  z-index: 2;
}

.ant-menu-inline-collapsed li .custom-menu-item-title {
  display: none;
}
.custom-ant-menu-item {
  padding-left: 32px !important;
}
.ant-menu-item a.active
// .ant-menu-item-selected 
 {
  background-color: #f0f2f5 !important;
  & .custom-menu-item-title {
    color: rgba(0, 0, 0, 1);
    font-weight: 700;
  }
  & svg {
    color: rgba(0, 0, 0, 1);
    font-weight: 700;
  }
}

.main-layout {
  border-radius: 30px;
  overflow: hidden;
}
.logo {
  position: fixed;
  padding: 0;
  margin-bottom: 0;
  top: 0;
  left: 0;
  z-index: 10;
  width: calc(215px + 1rem);
  background: #232a3b;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px #f0f2f5;
  @include mobile{
    width: calc(196px + 1rem);
  }
  @include desktop{
    width: calc(215px + 1rem);
  }
}
.logo > img {
  border-radius: 20px;
}
