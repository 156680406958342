.custom-layout {
  display: flex !important;
  /* padding: 10px 10px 10px 0px; */
  background-color: rgb(35, 42, 59) !important;
}
.ant-menu-item-selected {
  /* background-color: #9d2b26 !important; */
  color: #f3ba2f !important;
}

.ant-menu-dark .ant-menu-item-selected .anticon,
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #f3ba2f;
}

.custom-header {
  background: black !important;
}

.content {
  background: #fff;
}

div.ant-table-body {
  display: block !important;
  width: 100% !important;
  /* overflow: auto !important; */
  padding-bottom: 10px !important;
}
.w-100 {
  width: 100%;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 1.5em;
  height: 1.5em;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* .ant-calendar-picker-input {
  height: 100% !important;
} */

.hover-redirect:hover {
  box-shadow: 2px 2px #ab3747 !important;
  /* background-color: red; */
}

.hover-card:hover {
  box-shadow: 0 1px 1px 1px #ab3747 !important;
}

.deposit-card,
.withdraw-card {
  cursor: pointer;
  border: solid 1px #ccc;
  border-radius: 5px;
  text-align: center;
  font-weight: 700;
  /* height: 100px; */
}

.deposit-card:hover,
.withdraw-card:hover {
  border: 1px solid #ab3747;
  color: #ab3747;
}

.deposit-active,
.withdraw-active {
  border: 3px solid #ab3747;
  color: #ab3747;
}

.note {
  color: #ac4737;
}

.red-tag {
  color: #f5222d !important;
  background-color: #fff1f0 !important;
}

.green-tag {
  color: #52c41a !important;
}

.primary-tag {
  color: #2e85ca !important;
  background-color: #e6f7ff !important;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ant-table-body tbody {
  background: #fff;
  text-align: center;
}

.ant-table-body thead tr th {
  /* background-color: #e74c3c; */
  /* color: #fff; */
  font-weight: 700;
  text-align: center;
}
#custom-ant-table .ant-table-thead tr th,
#custom-ant-table .ant-table-header {
  /* background-color: #e74c3c; */
  /* color: #fff; */
  font-weight: 700;
}

#custom-ant-table .ant-table-thead tr th:first-child {
  text-align: center;
}

.box-shadow {
  /* -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.75); */
}

span {
  opacity: 1 !important;
}

/* login */
.card-login {
  height: auto;
  align-self: center;
}
.btn-custom {
  background-color: #ac4737 !important;
  color: #fff !important;
}

.btn-custom:hover {
  /* border: solid 2px #ac4757 !important; */
  opacity: 0.8;
}

.button-custom > svg {
  transition: all 0.2s;
  /* opacity: ; */
}

.button-custom:hover > svg {
  color: #ac4737;
}

.scroll-custom::-webkit-scrollbar {
  width: 5px;
  background-color: white;
  /* display: none; */
  opacity: 0;
  border: solid 1px #f5f5f5;
  border-bottom: none;
  border-radius: 10px;
}
.scroll-custom:hover::-webkit-scrollbar {
  display: inline;
}

.scroll-custom::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* background-color: #f5f5f5; */
  opacity: 0;
  transition: all 1s;
}

.scroll-custom:hover::-webkit-scrollbar-thumb {
  opacity: 1;
  background-color: #d62929;
}

@media screen and (max-width: 500px) {
  .mobile-container {
    padding: 0px !important;
  }
}

.verify .ant-input-group-addon {
  background-color: #e74c3c;
  color: #fff;
  cursor: pointer;
  padding: 0px;
}

.info-tag {
  padding: 5px;
  border-radius: 4px;
  color: #fff;
  background-color: #2196f3;
}

.tag {
  padding: 5px 10px;
  border-radius: 20px;
  color: #fff;
}

/* .tag-approved {
  background-color: ;
} */

.tag-completed {
  background-color: #5ac370;
}
.tag-handling {
  background-color: #f7872b;
}
.tag-pending {
  background-color: #ecb100f7;
}
.tag-canceled {
  background-color: #6d57cc;
}
.tag-info,
.tag-approved {
  background-color: #2196f3;
}

.label-custom {
  padding: 3px 5px;
  border-radius: 8px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: default;
  opacity: 1;
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.label-completed,
.label-active,
.label-success {
  background-color: #5ac370;
}

.label-approved {
  background-color: #2d95bf;
}
.label-handling {
  background-color: #f7872b;
}
.label-pending {
  background-color: #fba930;
}
.label-pending-approved {
  background-color: #f7872b;
}
/* .label-canceled {
  background-color: #6d57cc;
} */

.label-rejected,
.label-canceled,
.label-cancelled,
.label-disabled,
.label-exported {
  background-color: #e53935 !important;
}

.label-info {
  background-color: #2196f3 !important;
}

.label-delivering {
  background: #13c2c2;
}
.label-picking {
  background: #fa8c16;
}

.label-delivered {
  background: #1890ff;
}
.label-user_canceled,
.label-expired {
  background: #eb2f96;
}
.label-user_rejected {
  background: #f5222d;
}
/* .label-company_rejected {
} */
.label-company_canceled,
.label-lost_damage {
  background: #722ed1;
}
.label-ready {
  background: #52c41a;
}

.label-upcoming {
  background: #2196f3;
}
.label-running {
  background: #5ac370;
}

.label-handled {
  background: #5ac370;
}

.ellipse-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.ant-menu-submenu-selected > .ant-menu-submenu-title > .custom-menu-item-title {
  color: #fff;
}

.form-style-custom {
  box-shadow: 0px 3px 8px #0000000b;
  background-color: #fff;
  border-radius: 0.375rem;
  position: relative;
  padding: 0.5rem 1rem !important;
  min-height: 100px;
  background-position: right top;
  background-size: 15% auto;
  background-image: url(/static/media/bg.94b996ca.svg);
  background-repeat: no-repeat;
}

.ant-cascader-menu {
  height: auto !important;
  max-height: 180px;
}

.filter-label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
}

.custom-filter .ant-divider {
  margin: 10px;
}
.tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: 0 !important;
}

.ant-select-selection__clear {
  margin: -8px 20px 0 0 !important;
}

.negative-text {
  color: "#ccc";
}

.ant-menu-item-selected {
  background-color: #f0f2f5 !important;
  color: black !important;
}
.ant-menu-item-selected > a > span {
  color: black !important;
}
/* .side-nav .side-menu > li {
  background-color: #f0f2f5 !important;
} */

audio:focus {
  outline: none;
}
.title {
  font-weight: 500;
  display: block;
}
.error-text {
  color: #be2710;
}
.form-label-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.audio .ant-upload-list {
  display: none;
}

.ant-upload-list-item .ant-upload-list-item-actions {
  display: none;
}
.ant-upload-list-item:hover .ant-upload-list-item-actions {
  display: block;
}
